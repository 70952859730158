import React from "react";

import {
  Column,
  Text,
  Row,
  Input,
  Line,
  Img,
  Grid,
  Button,
  List,
} from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const AllBlogPage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Column className="items-center justify-start w-[100%]">
          <Header className="w-[100%]" />
          <Column className="items-center justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] w-[100%]">
            <Column className="items-center justify-start w-[44%]">
              <Text className="title1" as="h2" variant="h2">
                Read the latest news from around the world
              </Text>
              <Row className="bg-gray_50 items-center justify-end lg:mt-[55px] xl:mt-[69px] 2xl:mt-[78px] 3xl:mt-[93px] 2xl:pl-[10px] 3xl:pl-[12px] lg:pl-[7px] xl:pl-[8px] rounded-radius15 w-[100%]">
                <Input
                  className="p-[0] lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px] placeholder:text-black_900 w-[100%] columnaboutteam"
                  wrapClassName="w-[81%]"
                  name="FrameTwentyThree"
                  placeholder="Search New here..."
                  size="sm"
                  variant="FillGray50"
                ></Input>
                <Line className="bg-black_900_87 lg:h-[28px] xl:h-[34px] 2xl:h-[39px] 3xl:h-[46px] lg:ml-[4px] xl:ml-[5px] 2xl:ml-[6px] 3xl:ml-[7px] w-[1px]" />
                <Column className="bg-gray_50 items-center lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] rounded-radius15 w-[18%]">
                  <Row className="items-start justify-center xl:my-[10px] 2xl:my-[12px] 3xl:my-[14px] lg:my-[8px] w-[99%]">
                    <Text className="language" as="h6" variant="h6">
                      Filter
                    </Text>
                    <Img
                      src="images/img_trash.svg"
                      className="lg:h-[18px] xl:h-[22px] 2xl:h-[25px] 3xl:h-[29px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[7px] xl:ml-[9px] lg:w-[17px] xl:w-[21px] 2xl:w-[24px] 3xl:w-[28px]"
                      alt="trash"
                    />
                  </Row>
                </Column>
              </Row>
            </Column>
            <Row className="items-start 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] w-[90%]">
              <Column className="items-center justify-start w-[75%]">
                <Column className="items-center justify-start lg:pb-[16px] xl:pb-[20px] 2xl:pb-[23px] 3xl:pb-[27px] w-[100%]">
                  <Grid className="lg:gap-[14px] xl:gap-[17px] 2xl:gap-[20px] 3xl:gap-[24px] grid grid-cols-3 w-[100%]">
                    <Column className="items-center justify-start w-[100%]">
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </Column>
                    <Column className="items-center justify-start w-[100%]">
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </Column>
                    <Column className="items-center justify-start w-[100%]">
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </Column>
                    <Column className="items-center justify-start w-[100%]">
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </Column>
                    <Column className="items-center justify-start w-[100%]">
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </Column>
                    <Column className="items-center justify-start w-[100%]">
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </Column>
                  </Grid>
                </Column>
                <Row className="items-center justify-center lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] pb-[3px] pr-[3px] w-[25%]">
                  <Img
                    src="images/img_arrowleft_15X15.svg"
                    className="lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] lg:w-[10px] xl:w-[13px] 2xl:w-[15px] 3xl:w-[18px]"
                    alt="arrowleft"
                  />
                  <Button
                    className="font-normal lg:h-[25px] xl:h-[32px] 2xl:h-[36px] 3xl:h-[43px] 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 lg:w-[24px] xl:w-[31px] 2xl:w-[35px] 3xl:w-[42px]"
                    shape="RoundedBorder5"
                    size="md"
                    variant="FillBluegray900"
                  >
                    1
                  </Button>
                  <Button
                    className="font-normal lg:h-[25px] xl:h-[32px] 2xl:h-[36px] 3xl:h-[43px] 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 lg:w-[24px] xl:w-[31px] 2xl:w-[35px] 3xl:w-[42px]"
                    shape="RoundedBorder5"
                    size="md"
                    variant="FillGray100"
                  >
                    2
                  </Button>
                  <Button
                    className="font-normal lg:h-[25px] xl:h-[32px] 2xl:h-[36px] 3xl:h-[43px] 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 lg:w-[24px] xl:w-[31px] 2xl:w-[35px] 3xl:w-[42px]"
                    shape="RoundedBorder5"
                    size="md"
                    variant="FillGray100"
                  >
                    3
                  </Button>
                  <Button
                    className="flex lg:h-[25px] xl:h-[32px] 2xl:h-[36px] 3xl:h-[43px] items-center justify-center 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] lg:w-[24px] xl:w-[31px] 2xl:w-[35px] 3xl:w-[42px]"
                    shape="icbRoundedBorder5"
                    size="smIcn"
                    variant="icbFillGray100"
                  >
                    <Img
                      src="images/img_user.svg"
                      className="flex items-center justify-center lg:h-[15px] xl:h-[18px] 2xl:h-[21px] 3xl:h-[25px]"
                      alt="user"
                    />
                  </Button>
                  <Img
                    src="images/img_arrowright_15X15.svg"
                    className="lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] lg:mr-[11px] xl:mr-[14px] 2xl:mr-[16px] 3xl:mr-[19px] lg:w-[10px] xl:w-[13px] 2xl:w-[15px] 3xl:w-[18px]"
                    alt="arrowright"
                  />
                </Row>
              </Column>
              <Column className="justify-start lg:ml-[17px] xl:ml-[21px] 2xl:ml-[24px] 3xl:ml-[28px] w-[24%]">
                <Column className="justify-start pt-[3px] w-[82%]">
                  <Row className="items-center justify-between w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_900 placedate"
                      variant="body4"
                    >
                      New Released
                    </Text>
                    <Text
                      className="mb-[1px] text-black_900_90 placedate"
                      variant="body4"
                    >
                      Hot Topic
                    </Text>
                    <Text
                      className="mt-[1px] text-black_900_90 placedate"
                      variant="body4"
                    >
                      Popular
                    </Text>
                  </Row>
                  <Line className="bg-bluegray_900 h-[1px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[15px] lg:mt-[9px] w-[38%]" />
                </Column>
                <List
                  className="font-poppins gap-[0] min-h-[auto] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] pb-[1px] w-[100%]"
                  orientation="vertical"
                >
                  <Row className="items-start justify-between 2xl:my-[10px] 3xl:my-[12px] lg:my-[7px] xl:my-[9px] pt-[1px] w-[100%]">
                    <Img
                      src="images/img_unsplashg0gb14.png"
                      className="unsplashG0gbFourteen"
                      alt="unsplashG0gbFourteen"
                    />
                    <Column className="justify-start mt-[2px] w-[75%]">
                      <Text className="list" variant="body4">
                        Miami Dolphins won the match and officially qualified
                        for the final
                      </Text>
                      <Row className="font-inter items-center ml-[2px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[83%]">
                        <Text className="language_One" variant="body6">
                          New York, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration5" variant="body6">
                          - 10 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between 2xl:my-[10px] 3xl:my-[12px] lg:my-[7px] xl:my-[9px] pt-[1px] w-[100%]">
                    <Img
                      src="images/img_unsplashoj0zey.png"
                      className="unsplashG0gbFourteen"
                      alt="unsplashoj0zeY"
                    />
                    <Column className="justify-start mt-[2px] w-[75%]">
                      <Text className="list" variant="body4">
                        Miami Dolphins won the match and officially qualified
                        for the final
                      </Text>
                      <Row className="font-inter items-center ml-[2px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[83%]">
                        <Text className="language_One" variant="body6">
                          New York, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration5" variant="body6">
                          - 10 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between 2xl:my-[10px] 3xl:my-[12px] lg:my-[7px] xl:my-[9px] pt-[1px] w-[100%]">
                    <Img
                      src="images/img_unsplashlndpi8.png"
                      className="unsplashG0gbFourteen"
                      alt="unsplashLNdpiEight"
                    />
                    <Column className="justify-start mt-[2px] w-[75%]">
                      <Text className="list" variant="body4">
                        Miami Dolphins won the match and officially qualified
                        for the final
                      </Text>
                      <Row className="font-inter items-center ml-[2px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[83%]">
                        <Text className="language_One" variant="body6">
                          New York, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration5" variant="body6">
                          - 10 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between 2xl:my-[10px] 3xl:my-[12px] lg:my-[7px] xl:my-[9px] pt-[1px] w-[100%]">
                    <Img
                      src="images/img_unsplashjsubkj.png"
                      className="unsplashG0gbFourteen"
                      alt="unsplashJsuBKj"
                    />
                    <Column className="justify-start mt-[2px] w-[75%]">
                      <Text className="list" variant="body4">
                        Miami Dolphins won the match and officially qualified
                        for the final
                      </Text>
                      <Row className="font-inter items-center ml-[2px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[83%]">
                        <Text className="language_One" variant="body6">
                          New York, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration5" variant="body6">
                          - 10 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between 2xl:my-[10px] 3xl:my-[12px] lg:my-[7px] xl:my-[9px] pt-[1px] w-[100%]">
                    <Img
                      src="images/img_unsplashtvllfy.png"
                      className="unsplashG0gbFourteen"
                      alt="unsplashTVllFy"
                    />
                    <Column className="justify-start mt-[2px] w-[75%]">
                      <Text className="list" variant="body4">
                        Miami Dolphins won the match and officially qualified
                        for the final
                      </Text>
                      <Row className="font-inter items-center ml-[2px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[83%]">
                        <Text className="language_One" variant="body6">
                          New York, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration5" variant="body6">
                          - 10 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between 2xl:my-[10px] 3xl:my-[12px] lg:my-[7px] xl:my-[9px] pt-[1px] w-[100%]">
                    <Img
                      src="images/img_unsplashy5pg8z.png"
                      className="unsplashG0gbFourteen"
                      alt="unsplashy5PG8Z"
                    />
                    <Column className="justify-start mt-[2px] w-[75%]">
                      <Text className="list" variant="body4">
                        Miami Dolphins won the match and officially qualified
                        for the final
                      </Text>
                      <Row className="font-inter items-center ml-[2px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[83%]">
                        <Text className="language_One" variant="body6">
                          New York, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration5" variant="body6">
                          - 10 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                </List>
              </Column>
            </Row>
            <Footer className="xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]" />
          </Column>
        </Column>
      </Column>
    </>
  );
};

export default AllBlogPage;
