import React from "react";

import { Column, Text, Row, Button, List, Line, Img } from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const SubscribePage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Header className="w-[100%]" />
        <Column className="items-center justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] w-[100%]">
          <Column className="items-center justify-start w-[64%]">
            <Text className="title1" as="h2" variant="h2">
              Subscribe now for the latest and exclusive information alerts
            </Text>
            <Text
              className="font-normal lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[41px] xl:mt-[51px] 2xl:mt-[58px] 3xl:mt-[69px] not-italic text-black_900_7f text-center tracking-ls1 w-[100%]"
              as="h6"
              variant="h6"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </Text>
          </Column>
          <Column className="font-poppins items-center justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] w-[79%]">
            <Row className="bg-white_A700 items-center justify-center lg:pr-[27px] xl:pr-[34px] 2xl:pr-[39px] 3xl:pr-[46px] rounded-radius22 w-[19%]">
              <Button
                className="font-normal not-italic 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[61%]"
                shape="RoundedBorder8"
                size="xl"
                variant="FillBluegray900"
              >
                MONTHLY
              </Button>
              <Text
                className="lg:ml-[21px] xl:ml-[26px] 2xl:ml-[30px] 3xl:ml-[36px] language_One"
                variant="body6"
              >
                YEARLY
              </Text>
            </Row>
            <List
              className="lg:gap-[42px] xl:gap-[53px] 2xl:gap-[60px] 3xl:gap-[72px] grid grid-cols-3 min-h-[auto] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[100%]"
              orientation="horizontal"
            >
              <Column className="border border-bluegray_900 border-solid justify-end lg:my-[26px] xl:my-[32px] 2xl:my-[37px] 3xl:my-[44px] lg:p-[25px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[43px] w-[100%]">
                <Text
                  className="font-poppins ml-[4px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] not-italic text-black_900 w-[auto]"
                  as="h3"
                  variant="h3"
                >
                  Starter
                </Text>
                <Row className="items-end ml-[4px] mr-[auto] lg:mt-[22px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[52%]">
                  <Text className="language_One1" as="h2" variant="h2">
                    $20
                  </Text>
                  <Text
                    className="font-poppins mb-[1px] lg:ml-[3px] xl:ml-[4px] 2xl:ml-[5px] 3xl:ml-[6px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[15px] lg:mt-[9px] not-italic text-black_900_87 w-[auto]"
                    variant="body1"
                  >
                    /month
                  </Text>
                </Row>
                <Line className="bg-gray_200 h-[1px] ml-[4px] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[97%]" />
                <Column className="font-poppins justify-start ml-[4px] lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] w-[87%]">
                  <Text
                    className="not-italic text-black_900_a2 w-[auto]"
                    variant="body3"
                  >
                    Access All News
                  </Text>
                  <Text className="Ownanalyticsp" variant="body3">
                    Latest News Notification
                  </Text>
                  <Text className="Chatsupport" variant="body3">
                    Send News Articles
                  </Text>
                  <Text className="Chatsupport" variant="body3">
                    Latest Movie Recommendation
                  </Text>
                  <Text className="Unlimitedusers" variant="body3">
                    Updated News
                  </Text>
                </Column>
                <Button
                  className="font-inter font-normal ml-[4px] lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] not-italic lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px] text-center tracking-ls1 w-[97%]"
                  size="xl"
                  variant="OutlineBluegray900_1"
                >
                  Subscribe
                </Button>
              </Column>
              <Column className="border border-bluegray_900 border-solid items-center justify-end lg:p-[25px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[43px] w-[100%]">
                <Text
                  className="lg:mt-[4px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[8px] not-italic text-black_900 w-[auto]"
                  as="h3"
                  variant="h3"
                >
                  MOST POPULAR
                </Text>
                <Column className="justify-start lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[97%]">
                  <Text
                    className="font-poppins not-italic text-black_900 w-[auto]"
                    as="h3"
                    variant="h3"
                  >
                    Pro
                  </Text>
                  <Row className="items-end lg:mt-[22px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[62%]">
                    <Text className="language_One1" as="h2" variant="h2">
                      $100
                    </Text>
                    <Text
                      className="font-poppins mb-[1px] lg:ml-[5px] xl:ml-[7px] 2xl:ml-[8px] 3xl:ml-[9px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[15px] lg:mt-[9px] not-italic text-black_900_87 w-[auto]"
                      variant="body1"
                    >
                      /month
                    </Text>
                  </Row>
                  <Line className="bg-gray_200 h-[1px] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[100%]" />
                  <Column className="font-poppins justify-start lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] w-[90%]">
                    <Text
                      className="not-italic text-black_900_a2 w-[auto]"
                      variant="body3"
                    >
                      Access All News
                    </Text>
                    <Text className="Ownanalyticsp" variant="body3">
                      Latest News Notification
                    </Text>
                    <Text className="Chatsupport" variant="body3">
                      Send News Articles
                    </Text>
                    <Text className="Chatsupport" variant="body3">
                      Latest Movie Recommendation
                    </Text>
                    <Text className="Unlimitedusers" variant="body3">
                      Updated News
                    </Text>
                  </Column>
                  <Button
                    className="font-inter font-normal lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] not-italic lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px] text-center tracking-ls1 w-[100%]"
                    size="xl"
                    variant="FillBluegray900"
                  >
                    Subscribe
                  </Button>
                </Column>
              </Column>
              <Column className="border border-bluegray_900 border-solid justify-end lg:my-[26px] xl:my-[32px] 2xl:my-[37px] 3xl:my-[44px] lg:p-[25px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[43px] w-[100%]">
                <Text
                  className="font-poppins ml-[4px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[15px] lg:mt-[9px] not-italic text-black_900 w-[auto]"
                  as="h3"
                  variant="h3"
                >
                  Enterprise
                </Text>
                <Row className="items-end ml-[4px] mr-[auto] lg:mt-[20px] xl:mt-[25px] 2xl:mt-[29px] 3xl:mt-[34px] w-[60%]">
                  <Text className="language_One1" as="h2" variant="h2">
                    $200
                  </Text>
                  <Text
                    className="font-poppins mb-[1px] ml-[3px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[15px] lg:mt-[9px] not-italic text-black_900_87 w-[auto]"
                    variant="body1"
                  >
                    /month
                  </Text>
                </Row>
                <Line className="bg-gray_200 h-[1px] ml-[4px] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[97%]" />
                <Column className="font-poppins justify-start ml-[4px] lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] w-[87%]">
                  <Text
                    className="not-italic text-black_900_a2 w-[auto]"
                    variant="body3"
                  >
                    Access All News
                  </Text>
                  <Text className="Ownanalyticsp" variant="body3">
                    Latest News Notification
                  </Text>
                  <Text className="Chatsupport" variant="body3">
                    Send News Articles
                  </Text>
                  <Text className="Chatsupport" variant="body3">
                    Latest Movie Recommendation
                  </Text>
                  <Text className="Unlimitedusers" variant="body3">
                    Updated News
                  </Text>
                </Column>
                <Text
                  className="border border-bluegray_900 border-solid font-inter ml-[4px] lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] lg:px-[24px] xl:px-[31px] 2xl:px-[35px] 3xl:px-[42px] lg:py-[11px] xl:py-[14px] 2xl:py-[16px] 3xl:py-[19px] text-bluegray_900 placedate"
                  as="h6"
                  variant="h6"
                >
                  Contact
                </Text>
              </Column>
            </List>
          </Column>
          <Column className="font-inter items-center justify-start xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[90%]">
            <Column className="items-center justify-start w-[100%]">
              <Column className="items-center justify-start lg:pt-[3px] xl:pt-[4px] 2xl:pt-[5px] 3xl:pt-[6px] rounded-radius8 w-[100%]">
                <Row className="items-center justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Hot Topic News{" "}
                  </Text>
                  <Button
                    className="font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <List
                className="lg:gap-[14px] xl:gap-[17px] 2xl:gap-[20px] 3xl:gap-[24px] grid grid-cols-3 min-h-[auto] lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] w-[100%]"
                orientation="horizontal"
              >
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_14.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[189px] xl:ml-[237px] 2xl:ml-[267px] 3xl:ml-[320px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[28%]"
                          size="sm"
                          variant="FillDeeppurpleA700"
                        >
                          Entertaiment{" "}
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_15.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillOrangeA700"
                        >
                          Finance
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_16.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[211px] xl:ml-[265px] 2xl:ml-[298px] 3xl:ml-[357px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillRed900"
                        >
                          Political
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </List>
            </Column>
          </Column>
          <Footer className="font-inter xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]" />
        </Column>
      </Column>
    </>
  );
};

export default SubscribePage;
