import React from "react";

import {
  Column,
  Row,
  Text,
  Stack,
  Button,
  Img,
  List,
  Input,
  CheckBox,
} from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const LoginPage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Column className="items-center justify-start w-[100%]">
          <Header className="w-[100%]" />
          <Column className="justify-start lg:mt-[38px] xl:mt-[48px] 2xl:mt-[54px] 3xl:mt-[64px] w-[100%]">
            <Row className="items-center w-[95%]">
              <Column className="w-[54%]">
                <Text
                  className="leading-[normal] lg:ml-[53px] xl:ml-[66px] 2xl:ml-[75px] 3xl:ml-[90px] not-italic text-black_900 tracking-ls1 w-[83%]"
                  as="h1"
                  variant="h1"
                >
                  Welcome back, there is the latest news today !!
                </Text>
                <Stack className="lg:h-[281px] xl:h-[351px] 2xl:h-[395px] 3xl:h-[474px] lg:mt-[55px] xl:mt-[69px] 2xl:mt-[78px] 3xl:mt-[93px] w-[100%]">
                  <Row className="absolute items-start justify-center lg:pb-[16px] xl:pb-[20px] 2xl:pb-[23px] 3xl:pb-[27px] lg:pl-[16px] xl:pl-[20px] 2xl:pl-[23px] 3xl:pl-[27px] right-[3%] w-[97%]">
                    <Column className="items-center justify-start lg:ml-[216px] xl:ml-[270px] 2xl:ml-[304px] 3xl:ml-[365px] w-[33%]">
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </Column>
                    <List
                      className="lg:gap-[247px] xl:gap-[309px] 2xl:gap-[348px] 3xl:gap-[417px] grid grid-cols-2 min-h-[auto] lg:ml-[14px] xl:ml-[17px] 2xl:ml-[20px] 3xl:ml-[24px] w-[33%]"
                      orientation="horizontal"
                    >
                      <Column className="items-center justify-start w-[100%]">
                        <Column className="justify-start w-[100%]">
                          <Column
                            className="bg-cover bg-repeat items-center justify-start w-[100%]"
                            style={{
                              backgroundImage:
                                "url('images/img_background_181X308.png')",
                            }}
                          >
                            <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                              <Button
                                className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                                size="sm"
                                variant="FillDeeppurpleA700"
                              >
                                Entertaiment{" "}
                              </Button>
                              <Text
                                className="lg:mr-[121px] xl:mr-[152px] 2xl:mr-[171px] 3xl:mr-[205px] columnentertaiment_1"
                                variant="body6"
                              >
                                Ukraine, 24 april 2022
                              </Text>
                            </Column>
                          </Column>
                          <Column className="items-center justify-start lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[100%]">
                            <Text
                              className="font-inter leading-[normal] w-[100%] columnaboutteam"
                              variant="body4"
                            >
                              Zelensky accuses Russia of worst crimes since WW2{" "}
                            </Text>
                            <Text className="description10" variant="body6">
                              The Ukrainian leader says Russia must face an
                              international trial as he calls for the country to
                              be thrown off the UN Security Council. Lorem ipsom
                              dolor
                            </Text>
                          </Column>
                          <Button
                            className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                            rightIcon={
                              <Img
                                src="images/img_upload_15X15.png"
                                className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                                alt="upload"
                              />
                            }
                            shape="RoundedBorder8"
                            size="md"
                          >
                            <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                              Read More
                            </div>
                          </Button>
                        </Column>
                      </Column>
                      <Column className="justify-start w-[100%]">
                        <Column
                          className="bg-cover bg-repeat items-center justify-start w-[100%]"
                          style={{
                            backgroundImage: "url('images/img_group38_14.png')",
                          }}
                        >
                          <Column className="bg-gradient  justify-start xl:p-[11px] 2xl:p-[13px] 3xl:p-[15px] lg:p-[9px] w-[100%]">
                            <Button
                              className="font-bold lg:ml-[125px] xl:ml-[157px] 2xl:ml-[177px] 3xl:ml-[212px] mr-[2px] mt-[2px] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px] text-center tracking-ls1 w-[36%]"
                              size="sm"
                              variant="FillDeeppurpleA700"
                            >
                              Entertaiment{" "}
                            </Button>
                            <Text
                              className="columnentertaiment_1"
                              variant="body6"
                            >
                              Ukraine, 24 april 2022
                            </Text>
                          </Column>
                        </Column>
                        <Column className="items-center justify-start ml-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] w-[97%]">
                          <Text className="language8" variant="body4">
                            Zelensky accuses Russia of worst crimes since WW2{" "}
                          </Text>
                          <Text className="description10" variant="body6">
                            The Ukrainian leader says Russia must face an
                            international trial as he calls for the country to
                            be thrown off the UN Security Council. Lorem ipsom
                            dolor
                          </Text>
                        </Column>
                        <Button
                          className="2xl:mt-[22px] 3xl:mt-[26px] flex items-center justify-center lg:mt-[15px] text-center w-[45%] xl:mt-[19px]"
                          rightIcon={
                            <Img
                              src="images/img_upload.svg"
                              className="text-center lg:w-[10px] lg:h-[11px] lg:ml-[3px] xl:w-[13px] xl:h-[14px] xl:ml-[4px] 2xl:w-[15px] 2xl:h-[16px] 2xl:ml-[5px] 3xl:w-[18px] 3xl:h-[19px] 3xl:ml-[6px]"
                              alt="upload"
                            />
                          }
                          shape="RoundedBorder8"
                        >
                          <div className="bg-transparent font-normal not-italic xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] tracking-ls1">
                            Read More
                          </div>
                        </Button>
                      </Column>
                    </List>
                  </Row>
                  <Button
                    className="absolute flex lg:h-[43px] xl:h-[54px] 2xl:h-[61px] 3xl:h-[73px] inset-y-[0] items-center justify-center my-[auto] right-[0] rotate-[180deg] rounded-radius50 lg:w-[42px] xl:w-[53px] 2xl:w-[60px] 3xl:w-[72px]"
                    size="mdIcn"
                    variant="icbOutlineWhiteA700"
                  >
                    <Img
                      src="images/img_arrowright.svg"
                      className="flex items-center justify-center lg:h-[20px] xl:h-[25px] 2xl:h-[28px] 3xl:h-[33px]"
                      alt="arrowright"
                    />
                  </Button>
                </Stack>
              </Column>
              <Column className="bg-white_A700 border border-bluegray_900 border-solid items-center mb-[4px] 3xl:ml-[115px] lg:ml-[68px] xl:ml-[85px] 2xl:ml-[96px] lg:p-[34px] xl:p-[43px] 2xl:p-[49px] 3xl:p-[58px] w-[39%]">
                <Column className="items-center justify-start mt-[3px] w-[93%]">
                  <Text className="title1" as="h2" variant="h2">
                    Neuzy
                  </Text>
                  <Column className="items-center justify-start lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] w-[100%]">
                    <Column className="font-poppins items-center justify-start pt-[3px] w-[100%]">
                      <Column className="justify-start rounded-radius10 w-[100%]">
                        <Text className="title1" variant="body4">
                          Email
                        </Text>
                        <Input
                          className="placeholder:text-bluegray_400 Input"
                          wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                          type="email"
                          name="Input"
                          placeholder="Enter your email"
                          shape="RoundedBorder10"
                        ></Input>
                      </Column>
                      <Column className="justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[23px] 3xl:mt-[27px] rounded-radius10 w-[100%]">
                        <Text className="title1" variant="body4">
                          Password
                        </Text>
                        <Input
                          className="placeholder:text-bluegray_400 Input"
                          wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                          type="password"
                          name="Input One"
                          placeholder="Enter your password"
                          shape="RoundedBorder10"
                        ></Input>
                      </Column>
                      <Row className="items-end justify-between lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[99%]">
                        <CheckBox
                          className="font-medium lg:text-[11px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[19px] text-black_900 tracking-ls1"
                          inputClassName="h-[24px] mr-[5px] w-[24px]"
                          name="H4"
                          label="Remember me?"
                        ></CheckBox>
                        <Text
                          className="font-medium lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] text-black_900 tracking-ls1 w-[auto]"
                          variant="body2"
                        >
                          Forgot password
                        </Text>
                      </Row>
                    </Column>
                    <Button
                      className="font-medium font-poppins lg:mt-[21px] xl:mt-[26px] 2xl:mt-[30px] 3xl:mt-[36px] lg:text-[11px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[19px] text-center tracking-ls1 w-[100%]"
                      shape="RoundedBorder8"
                      size="xl"
                      variant="FillBluegray900"
                    >
                      Login
                    </Button>
                    <Text
                      className="lg:mt-[23px] xl:mt-[29px] 2xl:mt-[33px] 3xl:mt-[39px] language_One1"
                      variant="body4"
                    >
                      <span className="text-black_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                        Don't have an account?{" "}
                      </span>
                      <span className="text-bluegray_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                        Register here
                      </span>
                    </Text>
                  </Column>
                </Column>
              </Column>
            </Row>
            <Footer className="xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]" />
          </Column>
        </Column>
      </Column>
    </>
  );
};

export default LoginPage;
