import React from "react";

import { Column, Row, Text, Img, Line, Button, List } from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const SigleBlogPage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Header className="w-[100%]" />
        <Column className="items-center justify-start lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[100%]">
          <Column className="items-center justify-start pt-[4px] w-[41%]">
            <Row className="items-center justify-center w-[17%]">
              <div className="bg-bluegray_900 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] rounded-radius50 lg:w-[10px] xl:w-[13px] 2xl:w-[15px] 3xl:w-[18px]"></div>
              <Text
                className="2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] title1"
                as="h6"
                variant="h6"
              >
                Hot Topic
              </Text>
            </Row>
            <Text
              className="lg:mt-[21px] xl:mt-[26px] 2xl:mt-[30px] 3xl:mt-[36px] text-center list"
              as="h2"
              variant="h2"
            >
              Miami Dolphins won the match and officially qualified for the
              final
            </Text>
            <Row className="items-center justify-center lg:mt-[29px] xl:mt-[37px] 2xl:mt-[42px] 3xl:mt-[50px] w-[59%]">
              <Text className="language" as="h6" variant="h6">
                New York, 22 Agust 2022{" "}
              </Text>
              <Text
                className="mb-[1px] ml-[4px] text-black_900_87 placedate"
                as="h6"
                variant="h6"
              >
                <span className="text-black_900 font-inter lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px]">
                  -{" "}
                </span>
                <span className="text-black_900 font-inter lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px]">
                  10 minutes ago
                </span>
              </Text>
            </Row>
          </Column>
          <Img
            src="images/img_rectangle5_497X1440.png"
            className="lg:h-[354px] xl:h-[443px] 2xl:h-[498px] 3xl:h-[597px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[100%]"
            alt="RectangleFive"
          />
          <Column className="items-center justify-start lg:mt-[42px] xl:mt-[53px] 2xl:mt-[60px] 3xl:mt-[72px] w-[59%]">
            <Column className="items-center justify-start pt-[4px] w-[100%]">
              <Column className="justify-start w-[98%]">
                <Text className="title1" as="h2" variant="h2">
                  About Team
                </Text>
                <Text
                  className="lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[31px] xl:mt-[39px] 2xl:mt-[44px] 3xl:mt-[52px] w-[99%] columnaboutteam"
                  variant="body2"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </Text>
                <Text className="description_One1" variant="body2">
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </Text>
              </Column>
              <Img
                src="images/img_unsplashxhzbz.png"
                className="lg:h-[285px] xl:h-[356px] 2xl:h-[401px] 3xl:h-[481px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]"
                alt="unsplashxHZBz"
              />
            </Column>
            <Column className="justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] w-[99%]">
              <Text className="title1" as="h2" variant="h2">
                Officially Qualified for the Final
              </Text>
              <Text
                className="lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[28px] xl:mt-[35px] 2xl:mt-[40px] 3xl:mt-[48px] w-[99%] columnaboutteam"
                variant="body2"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Text>
              <Text
                className="lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[27px] xl:mt-[34px] 2xl:mt-[39px] 3xl:mt-[46px] w-[99%] columnaboutteam"
                variant="body2"
              >
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </Text>
              <Text className="description_One1" variant="body2">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </Text>
            </Column>
            <Column className="items-center justify-start lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] lg:pt-[4px] xl:pt-[6px] 2xl:pt-[7px] 3xl:pt-[8px] w-[100%]">
              <Column className="items-center justify-start w-[99%]">
                <Text className="title1" as="h2" variant="h2">
                  Typing skills and strategy are the keys to victory this time
                </Text>
                <Text
                  className="lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] w-[99%] columnaboutteam"
                  variant="body2"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </Text>
                <Text className="description_One1" variant="body2">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at Hampden-Sydney College in Virginia,
                  looked up one of the more obscure Latin words, consectetur,
                  from a Lorem Ipsum passage, and going through the cites of the
                  word in classical literature, discovered the undoubtable
                  source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
                  "de Finibus Bonorum et Malorum" (The Extremes of Good and
                  Evil) by Cicero, written in 45 BC. This book is a treatise on
                  the theory of ethics, very popular during the Renaissance. The
                  first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
                  comes from a line in section 1.10.32.
                </Text>
              </Column>
              <Img
                src="images/img_unsplash26mjgn.png"
                className="lg:h-[285px] xl:h-[356px] 2xl:h-[401px] 3xl:h-[481px] lg:mt-[20px] xl:mt-[25px] 2xl:mt-[29px] 3xl:mt-[34px] w-[100%]"
                alt="unsplash26MJGn"
              />
            </Column>
            <Column className="justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] w-[98%]">
              <Text className="title1" as="h2" variant="h2">
                Best regards from Miami Dolphins
              </Text>
              <Text
                className="lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[28px] xl:mt-[35px] 2xl:mt-[40px] 3xl:mt-[48px] w-[99%] columnaboutteam"
                variant="body2"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Text>
              <Text className="description_One1" variant="body2">
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </Text>
            </Column>
            <Line className="bg-black_900_4c h-[1px] lg:mt-[41px] xl:mt-[51px] 2xl:mt-[58px] 3xl:mt-[69px] w-[100%]" />
          </Column>
          <Column className="items-center justify-start lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] pl-[3px] w-[27%]">
            <Column className="items-center justify-start w-[100%]">
              <Row className="items-center justify-center w-[50%]">
                <Img
                  src="images/img_unsplashtwuphb.png"
                  className="lg:h-[43px] xl:h-[54px] 2xl:h-[61px] 3xl:h-[73px] rounded-radius50 lg:w-[42px] xl:w-[53px] 2xl:w-[60px] 3xl:w-[72px]"
                  alt="unsplashTwuPHb"
                />
                <Column className="2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] w-[64%]">
                  <Text className="language_One" as="h4" variant="h4">
                    Jhone RIck
                  </Text>
                  <Text
                    className="xl:mt-[10px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] not-italic text-black_900_7f tracking-ls1 w-[auto]"
                    variant="body5"
                  >
                    About Author
                  </Text>
                </Column>
              </Row>
              <Text
                className="lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[22px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] text-center w-[100%] columnaboutteam"
                variant="body4"
              >
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s.
              </Text>
            </Column>
            <Column className="items-center justify-start lg:mt-[43px] xl:mt-[54px] 2xl:mt-[61px] 3xl:mt-[73px] w-[45%]">
              <Text className="title1" as="h6" variant="h6">
                Connect with Author
              </Text>
              <Img
                src="images/img_group20875_24X112.svg"
                className="lg:h-[18px] xl:h-[22px] 2xl:h-[25px] 3xl:h-[29px] lg:mt-[18px] xl:mt-[23px] 2xl:mt-[26px] 3xl:mt-[31px] w-[65%]"
                alt="Group20875"
              />
            </Column>
          </Column>
          <Column className="items-center justify-start xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[90%]">
            <Column className="items-center justify-start w-[100%]">
              <Column className="items-center justify-start pt-[4px] rounded-radius8 w-[100%]">
                <Row className="items-start justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Related News
                  </Text>
                  <Button
                    className="font-normal mt-[1px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <List
                className="lg:gap-[14px] xl:gap-[17px] 2xl:gap-[20px] 3xl:gap-[24px] grid grid-cols-3 min-h-[auto] lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] w-[100%]"
                orientation="horizontal"
              >
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_14.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[189px] xl:ml-[237px] 2xl:ml-[267px] 3xl:ml-[320px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[28%]"
                          size="sm"
                          variant="FillDeeppurpleA700"
                        >
                          Entertaiment{" "}
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_16.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[200px] xl:ml-[250px] 2xl:ml-[282px] 3xl:ml-[338px] lg:mr-[14px] xl:mr-[18px] 2xl:mr-[21px] 3xl:mr-[25px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillRed900"
                        >
                          Political
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_15.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillOrangeA700"
                        >
                          Finance
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </List>
            </Column>
          </Column>
          <Footer className="xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]" />
        </Column>
      </Column>
    </>
  );
};

export default SigleBlogPage;
