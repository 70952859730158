import React from "react";

import {
  Column,
  Row,
  Img,
  Text,
  List,
  Button,
  Stack,
  PagerIndicator,
  Line,
} from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const HomepagePage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Header className="w-[100%]" />
        <Column className="items-center justify-start lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[100%]">
          <Column className="items-center justify-start w-[90%]">
            <Column className="items-center justify-start w-[100%]">
              <Row className="items-center justify-between w-[100%]">
                <Img
                  src="images/img_rectangle5.png"
                  className="lg:h-[182px] xl:h-[227px] 2xl:h-[256px] 3xl:h-[307px] w-[46%]"
                  alt="RectangleFive"
                />
                <Column className="w-[50%]">
                  <Column className="justify-start pt-[3px] w-[92%]">
                    <Row className="items-start w-[17%]">
                      <div className="bg-bluegray_900 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] rounded-radius50 lg:w-[10px] xl:w-[13px] 2xl:w-[15px] 3xl:w-[18px]"></div>
                      <Text
                        className="2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] mt-[1px] text-bluegray_900 placedate"
                        as="h6"
                        variant="h6"
                      >
                        Hot Topic
                      </Text>
                    </Row>
                    <Text
                      className="lg:mt-[21px] xl:mt-[26px] 2xl:mt-[30px] 3xl:mt-[36px] list"
                      as="h2"
                      variant="h2"
                    >
                      Miami Dolphins won the match and officially qualified for
                      the final
                    </Text>
                    <Row className="items-center lg:mt-[29px] xl:mt-[37px] 2xl:mt-[42px] 3xl:mt-[50px] w-[59%]">
                      <Text className="language" as="h6" variant="h6">
                        New York, 22 Agust 2022{" "}
                      </Text>
                      <Text
                        className="mb-[1px] ml-[4px] text-white_A700_87 placedate"
                        as="h6"
                        variant="h6"
                      >
                        <span className="text-white_A700 font-inter lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px]">
                          -{" "}
                        </span>
                        <span className="text-white_A700 font-inter lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px]">
                          10 minutes ago
                        </span>
                      </Text>
                    </Row>
                  </Column>
                  <Row className="items-center lg:mt-[34px] xl:mt-[42px] 2xl:mt-[48px] 3xl:mt-[57px] w-[17%]">
                    <Text
                      className="text-bluegray_900 placedate"
                      variant="body4"
                    >
                      Read More
                    </Text>
                    <Img
                      src="images/img_arrow1.svg"
                      className="xl:h-[1px] lg:h-[1px] 3xl:h-[2px] 2xl:h-[2px] xl:ml-[11px] 2xl:ml-[13px] 3xl:ml-[15px] lg:ml-[9px] w-[25%]"
                      alt="ArrowOne"
                    />
                  </Row>
                </Column>
              </Row>
              <Row className="items-center justify-between lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[100%]">
                <List
                  className="lg:gap-[39px] xl:gap-[48px] 2xl:gap-[55px] 3xl:gap-[66px] grid grid-cols-2 min-h-[auto] w-[65%]"
                  orientation="horizontal"
                >
                  <Row className="items-start lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[100%]">
                    <Img
                      src="images/img_rectangle1479.png"
                      className="Rectangle1479"
                      alt="Rectangle1479"
                    />
                    <Column className="justify-start 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] mt-[4px] w-[75%]">
                      <Text className="list" as="h6" variant="h6">
                        How to maximize investment with mutual funds
                      </Text>
                      <Row className="items-center lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[84%]">
                        <Text className="language_One" variant="body5">
                          Indonesia, 22 Agust 2022{" "}
                        </Text>
                        <Text
                          className="lg:ml-[4px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[8px] not-italic text-black_900_87 tracking-ls1 w-[auto]"
                          variant="body5"
                        >
                          - 15 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between lg:pr-[4px] xl:pr-[5px] 2xl:pr-[6px] 3xl:pr-[7px] w-[100%]">
                    <Img
                      src="images/img_rectangle1479_84X84.png"
                      className="Rectangle1479"
                      alt="Rectangle1479 One"
                    />
                    <Column className="justify-start mt-[4px] w-[76%]">
                      <Text className="list" as="h6" variant="h6">
                        john kennedy won 3rd oscar trophy at los angles
                      </Text>
                      <Row className="items-start ml-[1px] lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[81%]">
                        <Text className="mt-[3px] language_One" variant="body5">
                          Los Angles, 22 Agust 2022{" "}
                        </Text>
                        <Text
                          className="mb-[1px] ml-[4px] not-italic text-black_900_87 tracking-ls1 w-[auto]"
                          variant="body5"
                        >
                          - 22 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                </List>
                <Row className="items-start justify-between 3xl:pr-[10px] lg:pr-[6px] xl:pr-[8px] 2xl:pr-[9px] w-[30%]">
                  <Img
                    src="images/img_rectangle1479_1.png"
                    className="Rectangle1479"
                    alt="Rectangle1479 Two"
                  />
                  <Column className="justify-start mt-[4px] w-[75%]">
                    <Text className="list" as="h6" variant="h6">
                      Miami Dolphins won the match and officially qualified for
                      the final
                    </Text>
                    <Row className="items-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[79%]">
                      <Text className="mt-[3px] language_One" variant="body5">
                        New York, 22 Agust 2022{" "}
                      </Text>
                      <Text
                        className="mb-[1px] ml-[4px] not-italic text-black_900_87 tracking-ls1 w-[auto]"
                        variant="body5"
                      >
                        - 10 minutes ago
                      </Text>
                    </Row>
                  </Column>
                </Row>
              </Row>
            </Column>
          </Column>
          <Column className="items-center justify-start xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] pt-[4px] w-[90%]">
            <Row className="items-start justify-between rounded-radius8 w-[100%]">
              <Text className="title1" as="h2" variant="h2">
                Latest Release
              </Text>
              <Button
                className="font-normal mt-[1px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                shape="RoundedBorder8"
                variant="FillBluegray900"
              >
                View All
              </Button>
            </Row>
            <Row className="items-center justify-between lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[100%]">
              <Column className="w-[43%]">
                <Column
                  className="bg-cover bg-repeat items-center justify-start w-[100%]"
                  style={{ backgroundImage: "url('images/img_group38.png')" }}
                >
                  <Column className="bg-gradient  justify-end xl:p-[12px] 2xl:p-[14px] 3xl:p-[16px] lg:p-[9px] w-[100%]">
                    <Button
                      className="font-bold lg:ml-[300px] xl:ml-[376px] 2xl:ml-[423px] 3xl:ml-[507px] 3xl:mr-[10px] lg:mr-[6px] xl:mr-[8px] 2xl:mr-[9px] 3xl:mt-[10px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[17%]"
                      size="sm"
                      variant="FillDeeporangeA400"
                    >
                      Hot Topic
                    </Button>
                    <Text
                      className="lg:ml-[5px] xl:ml-[7px] 2xl:ml-[8px] 3xl:ml-[9px] lg:mr-[267px] xl:mr-[334px] 2xl:mr-[376px] 3xl:mr-[451px] lg:mt-[136px] xl:mt-[170px] 2xl:mt-[192px] 3xl:mt-[230px] text-white_A700 placedate"
                      variant="body4"
                    >
                      Ukraine, 24 april 2022
                    </Text>
                  </Column>
                </Column>
                <Column className="justify-start lg:mt-[22px] xl:mt-[27px] 2xl:mt-[31px] 3xl:mt-[37px] w-[95%]">
                  <Text className="language_One" as="h4" variant="h4">
                    Zelensky accuses Russia of worst crimes since WW2{" "}
                  </Text>
                  <Text
                    className="font-normal lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[40px] not-italic text-black_900_87 tracking-ls1 w-[96%]"
                    variant="body4"
                  >
                    The Ukrainian leader says Russia must face an international
                    trial as he calls for the country to be thrown off the UN
                    Security Council.
                  </Text>
                </Column>
                <Button
                  className="2xl:mt-[32px] 3xl:mt-[38px] flex items-center justify-center lg:mt-[22px] text-center w-[32%] xl:mt-[28px]"
                  rightIcon={
                    <Img
                      src="images/img_upload.svg"
                      className="text-center lg:w-[17px] lg:h-[18px] lg:ml-[3px] xl:w-[21px] xl:h-[22px] xl:ml-[4px] 2xl:w-[24px] 2xl:h-[25px] 2xl:ml-[5px] 3xl:w-[28px] 3xl:h-[29px] 3xl:ml-[6px]"
                      alt="upload"
                    />
                  }
                  shape="RoundedBorder8"
                >
                  <div className="bg-transparent font-normal not-italic lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px] tracking-ls1">
                    Read More
                  </div>
                </Button>
              </Column>
              <List
                className="gap-[0] min-h-[auto] w-[54%]"
                orientation="vertical"
              >
                <Row className="items-center justify-between lg:my-[17px] xl:my-[22px] 2xl:my-[25px] 3xl:my-[30px] w-[100%]">
                  <Img
                    src="images/img_unsplashj5keq1.png"
                    className="lg:h-[163px] xl:h-[204px] 2xl:h-[230px] 3xl:h-[275px] w-[43%]"
                    alt="unsplashj5kEQOne"
                  />
                  <Column className="pt-[3px] rounded-radius8 w-[53%]">
                    <Column className="justify-start pb-[4px] pr-[4px] w-[100%]">
                      <Text className="title1" variant="body4">
                        New York, 19 april 2022
                      </Text>
                      <Column className="font-poppins justify-start lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[96%]">
                        <Text
                          className="leading-[normal] text-black_900 tracking-ls1 w-[83%]"
                          as="h5"
                          variant="h5"
                        >
                          Jhon Lorni has won 1st place in international match
                        </Text>
                        <Text
                          className="lg:leading-[17px] xl:leading-[22px] 2xl:leading-[25px] 3xl:leading-[30px] lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] not-italic text-black_900_87 tracking-ls1 w-[100%]"
                          variant="body5"
                        >
                          this is his first victory in the international
                          olympics, so his name is quite explosive on the
                          international scene
                        </Text>
                      </Column>
                    </Column>
                    <Button
                      className="2xl:mt-[20px] 3xl:mt-[24px] flex items-center justify-center lg:mt-[14px] text-center w-[41%] xl:mt-[17px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[14px] lg:h-[15px] lg:ml-[3px] xl:w-[17px] xl:h-[18px] xl:ml-[4px] 2xl:w-[20px] 2xl:h-[21px] 2xl:ml-[5px] 3xl:w-[24px] 3xl:h-[25px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Row>
                <Row className="items-center justify-between lg:my-[17px] xl:my-[22px] 2xl:my-[25px] 3xl:my-[30px] w-[100%]">
                  <Img
                    src="images/img_unsplashl2p8f.png"
                    className="lg:h-[163px] xl:h-[204px] 2xl:h-[230px] 3xl:h-[275px] w-[43%]"
                    alt="unsplashL2p8f"
                  />
                  <Column className="pt-[3px] rounded-radius8 w-[53%]">
                    <Column className="justify-start pb-[4px] pr-[4px] w-[100%]">
                      <Text className="title1" variant="body4">
                        Amsterdam, 23 april 2022
                      </Text>
                      <Column className="font-poppins justify-start lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[99%]">
                        <Text
                          className="leading-[normal] text-black_900 tracking-ls1 w-[92%]"
                          as="h5"
                          variant="h5"
                        >
                          The extinction of the Arabian turtle made the
                          government...
                        </Text>
                        <Text
                          className="lg:leading-[17px] xl:leading-[22px] 2xl:leading-[25px] 3xl:leading-[30px] lg:mt-[12px] xl:mt-[15px] 2xl:mt-[17px] 3xl:mt-[20px] not-italic text-black_900_87 tracking-ls1 w-[100%]"
                          variant="body5"
                        >
                          The jalabiya turtle is a very unique species because
                          it is thought to have existed from ancient times, but
                          has begun to become..
                        </Text>
                      </Column>
                    </Column>
                    <Button
                      className="2xl:mt-[20px] 3xl:mt-[24px] flex items-center justify-center lg:mt-[14px] text-center w-[41%] xl:mt-[17px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[14px] lg:h-[15px] lg:ml-[3px] xl:w-[17px] xl:h-[18px] xl:ml-[4px] 2xl:w-[20px] 2xl:h-[21px] 2xl:ml-[5px] 3xl:w-[24px] 3xl:h-[25px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Row>
              </List>
            </Row>
          </Column>
          <Column className="items-center justify-start xl:mt-[112px] 2xl:mt-[127px] 3xl:mt-[152px] lg:mt-[90px] w-[100%]">
            <Text className="title1" as="h2" variant="h2">
              The Audience's choice of the Best Films
            </Text>
            <Stack className="3xl:h-[1124px] lg:h-[666px] xl:h-[833px] 2xl:h-[937px] lg:mt-[41px] xl:mt-[51px] 2xl:mt-[58px] 3xl:mt-[69px] w-[100%]">
              <Column className="absolute items-center justify-start top-[0] w-[100%]">
                <Column
                  className="bg-cover bg-repeat items-center justify-start w-[100%]"
                  style={{ backgroundImage: "url('images/img_slider.png')" }}
                >
                  <Row className="bg-gradient1  items-center lg:p-[53px] xl:p-[66px] 2xl:p-[75px] 3xl:p-[90px] w-[100%]">
                    <Row className="items-start justify-between lg:mb-[100px] xl:mb-[126px] 2xl:mb-[142px] 3xl:mb-[170px] lg:mt-[174px] xl:mt-[217px] 2xl:mt-[245px] 3xl:mt-[294px] w-[100%]">
                      <Img
                        src="images/img_arrowleft.svg"
                        className="lg:h-[43px] xl:h-[54px] 2xl:h-[61px] 3xl:h-[73px] 2xl:mb-[103px] 3xl:mb-[123px] lg:mb-[73px] xl:mb-[91px] lg:w-[42px] xl:w-[53px] 2xl:w-[60px] 3xl:w-[72px]"
                        alt="arrowleft"
                      />
                      <PagerIndicator
                        className="h-[15px] lg:mt-[105px] xl:mt-[131px] 2xl:mt-[148px] 3xl:mt-[177px] w-[max-content]"
                        count={5}
                        activeCss="inline-block cursor-pointer rounded-radius50 w-[15px] h-[15px] bg-white_A700"
                        activeIndex={1}
                        inactiveCss="inline-block cursor-pointer rounded-radius50 w-[15px] h-[15px] bg-white_A700_4f"
                        selectedWrapperCss="2xl:mx-[5px] 3xl:mx-[6px] inline-block lg:mx-[3px] xl:mx-[4px]"
                        unselectedWrapperCss="2xl:mx-[5px] 3xl:mx-[6px] inline-block lg:mx-[3px] xl:mx-[4px]"
                      />
                      <Button
                        className="flex lg:h-[43px] xl:h-[54px] 2xl:h-[61px] 3xl:h-[73px] items-center justify-center 2xl:mb-[103px] 3xl:mb-[123px] lg:mb-[73px] xl:mb-[91px] rotate-[180deg] rounded-radius50 lg:w-[42px] xl:w-[53px] 2xl:w-[60px] 3xl:w-[72px]"
                        size="mdIcn"
                        variant="icbOutlineWhiteA700"
                      >
                        <Img
                          src="images/img_arrowright.svg"
                          className="flex items-center justify-center lg:h-[20px] xl:h-[25px] 2xl:h-[28px] 3xl:h-[33px]"
                          alt="arrowright"
                        />
                      </Button>
                    </Row>
                  </Row>
                </Column>
              </Column>
              <List
                className="absolute bottom-[0] lg:gap-[14px] xl:gap-[17px] 2xl:gap-[20px] 3xl:gap-[24px] grid grid-cols-4 inset-x-[0] min-h-[auto] mx-[auto] w-[90%]"
                orientation="horizontal"
              >
                <Column className="bg-white_A700 items-center justify-start lg:pb-[15px] xl:pb-[19px] 2xl:pb-[22px] 3xl:pb-[26px] rounded-radius10 w-[100%]">
                  <Img
                    src="images/img_rectangle17.png"
                    className="RectangleSeventeen"
                    alt="RectangleSeventeen"
                  />
                  <Column className="items-center justify-start lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] w-[65%]">
                    <Text className="title1" as="h6" variant="h6">
                      Avengers Age of Ultron
                    </Text>
                    <Text className="GenresAction" variant="body4">
                      Genres : Action Adventure Sci-Fi
                    </Text>
                    <Img
                      src="images/img_group51.svg"
                      className="GroupFiftyOne"
                      alt="GroupFiftyOne"
                    />
                  </Column>
                </Column>
                <Column className="bg-white_A700 items-center justify-start lg:pb-[15px] xl:pb-[19px] 2xl:pb-[22px] 3xl:pb-[26px] rounded-radius10 w-[100%]">
                  <Img
                    src="images/img_rectangle17_307X308.png"
                    className="RectangleSeventeen"
                    alt="RectangleSeventeen One"
                  />
                  <Column className="items-center justify-start lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] w-[65%]">
                    <Text className="title1" as="h6" variant="h6">
                      Captain Marvel
                    </Text>
                    <Text className="GenresAction" variant="body4">
                      Genres : Action Adventure Sci-Fi
                    </Text>
                    <Img
                      src="images/img_group51.svg"
                      className="GroupFiftyOne"
                      alt="GroupFiftyOne One"
                    />
                  </Column>
                </Column>
                <Column className="bg-white_A700 items-center justify-start lg:pb-[15px] xl:pb-[19px] 2xl:pb-[22px] 3xl:pb-[26px] rounded-radius10 w-[100%]">
                  <Img
                    src="images/img_rectangle17_1.png"
                    className="RectangleSeventeen"
                    alt="RectangleSeventeen Two"
                  />
                  <Column className="items-center justify-start lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] w-[65%]">
                    <Text className="title1" as="h6" variant="h6">
                      Avengers End Game
                    </Text>
                    <Text
                      className="lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] text-black_900_7f placedate"
                      variant="body4"
                    >
                      Genres : Action Adventure Sci-Fi
                    </Text>
                    <Img
                      src="images/img_group51.svg"
                      className="GroupFiftyOne"
                      alt="GroupFiftyOne Two"
                    />
                  </Column>
                </Column>
                <Column className="bg-white_A700 items-center justify-start lg:pb-[15px] xl:pb-[19px] 2xl:pb-[22px] 3xl:pb-[26px] rounded-radius10 w-[100%]">
                  <Img
                    src="images/img_rectangle17_2.png"
                    className="RectangleSeventeen"
                    alt="RectangleSeventeen Three"
                  />
                  <Column className="items-center justify-start lg:mt-[17px] xl:mt-[21px] 2xl:mt-[24px] 3xl:mt-[28px] w-[70%]">
                    <Text className="title1" as="h6" variant="h6">
                      Spiderman Far from Home
                    </Text>
                    <Text className="GenresAction" variant="body4">
                      Genres : Action Adventure Sci-Fi
                    </Text>
                    <Img
                      src="images/img_group51.svg"
                      className="lg:h-[15px] xl:h-[18px] 2xl:h-[21px] 3xl:h-[25px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[46%]"
                      alt="GroupFiftyOne Three"
                    />
                  </Column>
                </Column>
              </List>
            </Stack>
            <Button
              className="font-normal lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[8%]"
              shape="RoundedBorder8"
              variant="FillBluegray900"
            >
              View All
            </Button>
          </Column>
          <Column className="bg-black_900 items-center justify-start xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] lg:p-[35px] xl:p-[44px] 2xl:p-[50px] 3xl:p-[60px] w-[100%]">
            <Column className="items-center justify-start w-[96%]">
              <Row className="items-center justify-between w-[100%]">
                <Column className="items-center w-[62%]">
                  <Column
                    className="bg-cover bg-repeat items-center justify-start w-[100%]"
                    style={{
                      backgroundImage: "url('images/img_background.png')",
                    }}
                  >
                    <Column className="bg-gradient2  items-center justify-end lg:p-[32px] xl:p-[40px] 2xl:p-[45px] 3xl:p-[54px] w-[100%]">
                      <Column className="items-center justify-start lg:mt-[160px] xl:mt-[200px] 2xl:mt-[225px] 3xl:mt-[270px] lg:px-[12px] xl:px-[15px] 2xl:px-[17px] 3xl:px-[20px] w-[51%]">
                        <Column className="items-center justify-start pt-[3px] w-[99%]">
                          <Row className="items-center justify-center w-[47%]">
                            <div className="bg-bluegray_900 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] rounded-radius50 lg:w-[10px] xl:w-[13px] 2xl:w-[15px] 3xl:w-[18px]"></div>
                            <Text
                              className="2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] text-white_A700 placedate"
                              variant="body4"
                            >
                              Entertainment Topic
                            </Text>
                          </Row>
                          <Text
                            className="font-normal leading-[normal] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] not-italic text-center text-white_A700 tracking-ls1 w-[100%]"
                            as="h2"
                            variant="h2"
                          >
                            Best Place for Vacation in Sydney
                          </Text>
                        </Column>
                        <Button
                          className="font-normal lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] not-italic lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px] text-center tracking-ls1 w-[48%]"
                          shape="RoundedBorder8"
                          variant="OutlineWhiteA700"
                        >
                          Read Now
                        </Button>
                      </Column>
                      <PagerIndicator
                        className="h-[15px] lg:mt-[39px] xl:mt-[48px] 2xl:mt-[55px] 3xl:mt-[66px] w-[max-content]"
                        count={5}
                        activeCss="inline-block cursor-pointer rounded-radius50 w-[15px] h-[15px] bg-white_A700"
                        activeIndex={1}
                        inactiveCss="inline-block cursor-pointer rounded-radius50 w-[15px] h-[15px] bg-white_A700_4f"
                        selectedWrapperCss="2xl:mx-[5px] 3xl:mx-[6px] inline-block lg:mx-[3px] xl:mx-[4px]"
                        unselectedWrapperCss="2xl:mx-[5px] 3xl:mx-[6px] inline-block lg:mx-[3px] xl:mx-[4px]"
                      />
                    </Column>
                  </Column>
                </Column>
                <List
                  className="gap-[0] min-h-[auto] w-[34%]"
                  orientation="vertical"
                >
                  <Row className="items-start justify-between lg:my-[16px] xl:my-[20px] 2xl:my-[22px] 3xl:my-[27px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[100%]">
                    <Img
                      src="images/img_rectangle1479_84X121.png"
                      className="Rectangle1479_Three"
                      alt="Rectangle1479 Three"
                    />
                    <Column className="justify-start mt-[4px] w-[66%]">
                      <Text className="list2" as="h6" variant="h6">
                        How to maximize investment with mutual funds
                      </Text>
                      <Row className="items-center lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[84%]">
                        <Text className="language_Five" variant="body5">
                          Indonesia, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration_Four" variant="body5">
                          - 15 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between lg:my-[16px] xl:my-[20px] 2xl:my-[22px] 3xl:my-[27px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[100%]">
                    <Img
                      src="images/img_rectangle1479_2.png"
                      className="Rectangle1479_Three"
                      alt="Rectangle1479 Four"
                    />
                    <Column className="justify-start mt-[4px] w-[66%]">
                      <Text className="list2" as="h6" variant="h6">
                        How to maximize investment with mutual funds
                      </Text>
                      <Row className="items-center lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[84%]">
                        <Text className="language_Five" variant="body5">
                          Indonesia, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration_Four" variant="body5">
                          - 15 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between lg:my-[16px] xl:my-[20px] 2xl:my-[22px] 3xl:my-[27px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[100%]">
                    <Img
                      src="images/img_rectangle1479_3.png"
                      className="Rectangle1479_Three"
                      alt="Rectangle1479 Five"
                    />
                    <Column className="justify-start mt-[4px] w-[66%]">
                      <Text className="list2" as="h6" variant="h6">
                        How to maximize investment with mutual funds
                      </Text>
                      <Row className="items-center lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[84%]">
                        <Text className="language_Five" variant="body5">
                          Indonesia, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration_Four" variant="body5">
                          - 15 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between lg:my-[16px] xl:my-[20px] 2xl:my-[22px] 3xl:my-[27px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[100%]">
                    <Img
                      src="images/img_rectangle1479_4.png"
                      className="Rectangle1479_Three"
                      alt="Rectangle1479 Six"
                    />
                    <Column className="justify-start mt-[4px] w-[66%]">
                      <Text className="list2" as="h6" variant="h6">
                        How to maximize investment with mutual funds
                      </Text>
                      <Row className="items-center lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[84%]">
                        <Text className="language_Five" variant="body5">
                          Indonesia, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration_Four" variant="body5">
                          - 15 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                  <Row className="items-start justify-between lg:my-[16px] xl:my-[20px] 2xl:my-[22px] 3xl:my-[27px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[100%]">
                    <Img
                      src="images/img_rectangle1479_5.png"
                      className="Rectangle1479_Three"
                      alt="Rectangle1479 Seven"
                    />
                    <Column className="justify-start mt-[4px] w-[66%]">
                      <Text className="list2" as="h6" variant="h6">
                        How to maximize investment with mutual funds
                      </Text>
                      <Row className="items-center lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[84%]">
                        <Text className="language_Five" variant="body5">
                          Indonesia, 22 Agust 2022{" "}
                        </Text>
                        <Text className="duration_Four" variant="body5">
                          - 15 minutes ago
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                </List>
              </Row>
            </Column>
          </Column>
          <Column className="items-center justify-start xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]">
            <Column
              className="bg-cover bg-repeat items-center justify-start w-[100%]"
              style={{
                backgroundImage: "url('images/img_background_500X1440.png')",
              }}
            >
              <Row className="bg-gradient3  items-center lg:p-[27px] xl:p-[34px] 2xl:p-[39px] 3xl:p-[46px] w-[100%]">
                <Row className="items-start lg:ml-[25px] xl:ml-[32px] 2xl:ml-[36px] 3xl:ml-[43px] lg:mt-[107px] xl:mt-[134px] 2xl:mt-[151px] 3xl:mt-[181px] w-[94%]">
                  <Column className="justify-start lg:mb-[17px] xl:mb-[22px] 2xl:mb-[25px] 3xl:mb-[30px] pt-[3px] w-[42%]">
                    <Row className="items-start w-[16%]">
                      <div className="bg-deep_orange_A400 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] rounded-radius50 lg:w-[10px] xl:w-[13px] 2xl:w-[15px] 3xl:w-[18px]"></div>
                      <Text
                        className="2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[8px] mt-[1px] text-white_A700 placedate"
                        variant="body4"
                      >
                        Life Topic
                      </Text>
                    </Row>
                    <Text
                      className="lg:mt-[12px] xl:mt-[15px] 2xl:mt-[17px] 3xl:mt-[20px] language_Five"
                      as="h1"
                      variant="h1"
                    >
                      St Pete's most beautiful beach in Florida
                    </Text>
                    <Button
                      className="font-normal lg:mt-[49px] xl:mt-[61px] 2xl:mt-[69px] 3xl:mt-[82px] not-italic lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px] text-center tracking-ls1 w-[30%]"
                      shape="RoundedBorder8"
                      variant="OutlineWhiteA700"
                    >
                      Read More
                    </Button>
                  </Column>
                  <PagerIndicator
                    className="h-[15px] lg:ml-[36px] xl:ml-[45px] 2xl:ml-[51px] 3xl:ml-[61px] lg:mt-[182px] xl:mt-[227px] 2xl:mt-[256px] 3xl:mt-[307px] w-[max-content]"
                    count={5}
                    activeCss="inline-block cursor-pointer rounded-radius50 w-[15px] h-[15px] bg-white_A700"
                    activeIndex={1}
                    inactiveCss="inline-block cursor-pointer rounded-radius50 w-[15px] h-[15px] bg-white_A700_4f"
                    selectedWrapperCss="2xl:mx-[5px] 3xl:mx-[6px] inline-block lg:mx-[3px] xl:mx-[4px]"
                    unselectedWrapperCss="2xl:mx-[5px] 3xl:mx-[6px] inline-block lg:mx-[3px] xl:mx-[4px]"
                  />
                  <Img
                    src="images/img_arrowright_60X60.svg"
                    className="lg:h-[43px] xl:h-[54px] 2xl:h-[61px] 3xl:h-[73px] lg:ml-[373px] xl:ml-[467px] 2xl:ml-[525px] 3xl:ml-[630px] lg:mt-[21px] xl:mt-[26px] 2xl:mt-[30px] 3xl:mt-[36px] lg:w-[42px] xl:w-[53px] 2xl:w-[60px] 3xl:w-[72px]"
                    alt="arrowright One"
                  />
                </Row>
              </Row>
            </Column>
          </Column>
          <List
            className="gap-[0] min-h-[auto] xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[90%]"
            orientation="vertical"
          >
            <Column className="items-center justify-start lg:my-[42px] xl:my-[53px] 2xl:my-[60px] 3xl:my-[72px] w-[100%]">
              <Column className="items-center justify-start pt-[3px] rounded-radius8 w-[100%]">
                <Row className="items-start justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Entertaiment{" "}
                  </Text>
                  <Button
                    className="font-normal mt-[1px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <Row className="items-start justify-between lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[100%]">
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage:
                          "url('images/img_group38_246X418.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[189px] xl:ml-[237px] 2xl:ml-[267px] 3xl:ml-[320px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[28%]"
                          size="sm"
                          variant="FillDeeppurpleA700"
                        >
                          Entertaiment{" "}
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_1.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[189px] xl:ml-[237px] 2xl:ml-[267px] 3xl:ml-[320px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[28%]"
                          size="sm"
                          variant="FillDeeppurpleA700"
                        >
                          Entertaiment{" "}
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_2.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[189px] xl:ml-[237px] 2xl:ml-[267px] 3xl:ml-[320px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[28%]"
                          size="sm"
                          variant="FillDeeppurpleA700"
                        >
                          Entertaiment{" "}
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </Row>
            </Column>
            <Column className="items-center justify-start lg:my-[42px] xl:my-[53px] 2xl:my-[60px] 3xl:my-[72px] w-[100%]">
              <Column className="items-center justify-start pt-[3px] rounded-radius8 w-[100%]">
                <Row className="items-start justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Political{" "}
                  </Text>
                  <Button
                    className="font-normal mt-[1px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <Row className="items-start justify-between lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[100%]">
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage:
                          "url('images/img_group38_247X418.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillRed900"
                        >
                          Political
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_3.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillRed900"
                        >
                          Political
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_4.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillRed900"
                        >
                          Political
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </Row>
            </Column>
            <Column className="items-center justify-start lg:my-[42px] xl:my-[53px] 2xl:my-[60px] 3xl:my-[72px] w-[100%]">
              <Column className="items-center justify-start lg:pt-[3px] xl:pt-[4px] 2xl:pt-[5px] 3xl:pt-[6px] rounded-radius8 w-[100%]">
                <Row className="items-center justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Sport{" "}
                  </Text>
                  <Button
                    className="font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <Row className="items-start justify-between lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[100%]">
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_5.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[221px] xl:ml-[277px] 2xl:ml-[312px] 3xl:ml-[374px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[17%]"
                          size="sm"
                          variant="FillGreen700"
                        >
                          Sport
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_6.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[221px] xl:ml-[277px] 2xl:ml-[312px] 3xl:ml-[374px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[17%]"
                          size="sm"
                          variant="FillGreen700"
                        >
                          Sport
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_7.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[221px] xl:ml-[277px] 2xl:ml-[312px] 3xl:ml-[374px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[17%]"
                          size="sm"
                          variant="FillGreen700"
                        >
                          Sport
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </Row>
            </Column>
            <Column className="items-center justify-start lg:my-[42px] xl:my-[53px] 2xl:my-[60px] 3xl:my-[72px] w-[100%]">
              <Column className="items-center justify-start pt-[4px] rounded-radius8 w-[100%]">
                <Row className="items-start justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Health{" "}
                  </Text>
                  <Button
                    className="font-normal mt-[1px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <Row className="items-start justify-between lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[100%]">
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_8.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[219px] xl:ml-[273px] 2xl:ml-[308px] 3xl:ml-[369px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[18%]"
                          size="sm"
                          variant="FillPurple500"
                        >
                          Health
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_9.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[219px] xl:ml-[273px] 2xl:ml-[308px] 3xl:ml-[369px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[18%]"
                          size="sm"
                          variant="FillPurple500"
                        >
                          Health
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_10.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[219px] xl:ml-[273px] 2xl:ml-[308px] 3xl:ml-[369px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[18%]"
                          size="sm"
                          variant="FillPurple500"
                        >
                          Health
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </Row>
            </Column>
            <Column className="items-center justify-start lg:my-[42px] xl:my-[53px] 2xl:my-[60px] 3xl:my-[72px] w-[100%]">
              <Column className="items-center justify-start pt-[3px] rounded-radius8 w-[100%]">
                <Row className="items-start justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Finance{" "}
                  </Text>
                  <Button
                    className="font-normal mt-[1px] not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <Row className="items-start justify-between lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[100%]">
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_11.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillOrangeA700"
                        >
                          Finance
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_12.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillOrangeA700"
                        >
                          Finance
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[32%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_13.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillOrangeA700"
                        >
                          Finance
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </Row>
            </Column>
          </List>
          <Column className="bg-gray_900 items-center justify-start xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] lg:p-[26px] xl:p-[32px] 2xl:p-[37px] 3xl:p-[44px] rounded-radius20 w-[90%]">
            <Column className="items-center justify-start lg:mb-[3px] xl:mb-[4px] 2xl:mb-[5px] 3xl:mb-[6px] 3xl:pt-[10px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] 3xl:px-[10px] lg:px-[6px] xl:px-[8px] 2xl:px-[9px] rounded-radius501 w-[54%]">
              <Column className="items-center justify-start w-[98%]">
                <Text className="language_Five" as="h1" variant="h1">
                  Get the Latest Notifications and Info from Us
                </Text>
                <Text
                  className="font-normal lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[42px] xl:mt-[53px] 2xl:mt-[60px] 3xl:mt-[72px] not-italic text-center text-white_A700_b2 tracking-ls1 w-[100%]"
                  variant="body2"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled.
                </Text>
              </Column>
              <Button
                className="font-normal lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] not-italic lg:text-[12px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[21px] text-center tracking-ls1 w-[31%]"
                shape="CircleBorder26"
                size="xl"
                variant="OutlineBluegray900"
              >
                Subscribe Now
              </Button>
            </Column>
          </Column>
          <Footer className="xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]" />
        </Column>
      </Column>
    </>
  );
};

export default HomepagePage;
