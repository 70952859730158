import React from "react";

import { Column, Row, Text, Input, Button } from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const ForgotPasswordPage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Column className="items-center justify-start w-[100%]">
          <Header className="w-[100%]" />
          <Column className="items-center justify-start lg:mt-[49px] xl:mt-[62px] 2xl:mt-[70px] 3xl:mt-[84px] w-[100%]">
            <Row className="items-start justify-between w-[90%]">
              <Column className="justify-start 3xl:mt-[10px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] w-[47%]">
                <Text
                  className="leading-[normal] not-italic text-black_900 tracking-ls1 w-[100%]"
                  as="h1"
                  variant="h1"
                >
                  You forgot your password? calm down we will help
                </Text>
                <Text
                  className="font-normal lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[41px] xl:mt-[51px] 2xl:mt-[58px] 3xl:mt-[69px] not-italic text-black_900_87 tracking-ls1 w-[90%]"
                  as="h6"
                  variant="h6"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                </Text>
              </Column>
              <Column className="bg-white_A700 border border-bluegray_900 border-solid items-center justify-end lg:p-[36px] xl:p-[46px] 2xl:p-[52px] 3xl:p-[62px] w-[41%]">
                <Column className="items-center justify-start lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] rounded-radius10 w-[94%]">
                  <Text className="title1" as="h2" variant="h2">
                    Neuzy
                  </Text>
                  <Column className="justify-start lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] pt-[3px] rounded-radius10 w-[100%]">
                    <Column className="font-poppins justify-start rounded-radius10 w-[100%]">
                      <Text className="title1" variant="body4">
                        Email
                      </Text>
                      <Input
                        className="placeholder:text-bluegray_400 Input"
                        wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                        type="email"
                        name="Input"
                        placeholder="Enter your email"
                        shape="RoundedBorder10"
                      ></Input>
                    </Column>
                    <Button
                      className="font-medium font-poppins lg:mt-[21px] xl:mt-[26px] 2xl:mt-[30px] 3xl:mt-[36px] lg:text-[11px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[19px] text-center tracking-ls1 w-[100%]"
                      shape="RoundedBorder8"
                      size="2xl"
                      variant="FillBluegray900"
                    >
                      Reset Password
                    </Button>
                    <Text className="columnregister_one" variant="body4">
                      <span className="text-black_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                        Do you already have an account??{" "}
                      </span>
                      <span className="text-bluegray_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                        Login Now
                      </span>
                    </Text>
                  </Column>
                </Column>
              </Column>
            </Row>
            <Footer className="xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]" />
          </Column>
        </Column>
      </Column>
    </>
  );
};

export default ForgotPasswordPage;
