import React from "react";

import { Column, Text, Input, Button, Row, Line, List, Img } from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const RegisterPage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Header className="w-[100%]" />
        <Column className="items-center justify-start lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] w-[100%]">
          <Column className="items-center justify-start w-[41%]">
            <Text className="language_One" as="h1" variant="h1">
              Let's join as Neuzy friends
            </Text>
            <Text
              className="font-normal lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[31px] xl:mt-[39px] 2xl:mt-[44px] 3xl:mt-[52px] not-italic text-black_900_7f text-center tracking-ls1 w-[92%]"
              as="h6"
              variant="h6"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown
            </Text>
          </Column>
          <Column className="bg-white_A700 border border-bluegray_900 border-solid items-center justify-end lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] lg:p-[39px] xl:p-[49px] 2xl:p-[56px] 3xl:p-[67px] w-[37%]">
            <Column className="items-center justify-start mt-[4px] rounded-radius10 w-[96%]">
              <Text className="title1" as="h2" variant="h2">
                Neuzy
              </Text>
              <Column className="justify-start lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] rounded-radius10 w-[100%]">
                <Column className="font-poppins items-center justify-start pt-[4px] rounded-radius10 w-[100%]">
                  <Column className="justify-start rounded-radius10 w-[100%]">
                    <Text className="title1" variant="body4">
                      Name
                    </Text>
                    <Input
                      className="placeholder:text-bluegray_400 Input"
                      wrapClassName="2xl:mt-[13px] 3xl:mt-[15px] lg:mt-[9px] w-[100%] xl:mt-[11px]"
                      type="text"
                      name="Input"
                      placeholder="Enter your Name"
                      shape="RoundedBorder10"
                    ></Input>
                  </Column>
                  <Column className="justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[23px] 3xl:mt-[27px] rounded-radius10 w-[100%]">
                    <Text className="title1" variant="body4">
                      Email
                    </Text>
                    <Input
                      className="placeholder:text-bluegray_400 Input"
                      wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                      type="email"
                      name="Input One"
                      placeholder="Enter your email"
                      shape="RoundedBorder10"
                    ></Input>
                  </Column>
                  <Column className="justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[23px] 3xl:mt-[27px] rounded-radius10 w-[100%]">
                    <Text className="title1" variant="body4">
                      Password
                    </Text>
                    <Input
                      className="placeholder:text-bluegray_400 Input"
                      wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                      type="password"
                      name="Input Two"
                      placeholder="Enter your password"
                      shape="RoundedBorder10"
                    ></Input>
                  </Column>
                  <Column className="justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[23px] 3xl:mt-[27px] rounded-radius10 w-[100%]">
                    <Text className="title1" variant="body4">
                      Confirm Password
                    </Text>
                    <Input
                      className="placeholder:text-bluegray_400 Input"
                      wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                      type="password"
                      name="Input Three"
                      placeholder="Enter your password"
                      shape="RoundedBorder10"
                    ></Input>
                  </Column>
                </Column>
                <Button
                  className="font-medium font-poppins lg:mt-[21px] xl:mt-[26px] 2xl:mt-[30px] 3xl:mt-[36px] lg:text-[11px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[19px] text-center tracking-ls1 w-[100%]"
                  shape="RoundedBorder8"
                  size="xl"
                  variant="FillBluegray900"
                >
                  Register
                </Button>
                <Text className="columnregister_one" variant="body4">
                  <span className="text-black_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                    Do you already have an account??{" "}
                  </span>
                  <span className="text-bluegray_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                    Login Now
                  </span>
                </Text>
              </Column>
            </Column>
          </Column>
          <Column className="items-center justify-start xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[40px] w-[90%]">
            <Column className="items-center justify-start w-[100%]">
              <Column className="items-center justify-start lg:pt-[3px] xl:pt-[4px] 2xl:pt-[5px] 3xl:pt-[6px] rounded-radius8 w-[100%]">
                <Row className="items-center justify-between rounded-radius8 w-[100%]">
                  <Text className="title1" as="h2" variant="h2">
                    Hot Topic News{" "}
                  </Text>
                  <Button
                    className="font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-center tracking-ls1 w-[9%]"
                    shape="RoundedBorder8"
                    variant="FillBluegray900"
                  >
                    View All
                  </Button>
                </Row>
                <Line className="bg-black_900_7f h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]" />
              </Column>
              <List
                className="lg:gap-[14px] xl:gap-[17px] 2xl:gap-[20px] 3xl:gap-[24px] grid grid-cols-3 min-h-[auto] lg:mt-[34px] xl:mt-[43px] 2xl:mt-[49px] 3xl:mt-[58px] w-[100%]"
                orientation="horizontal"
              >
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_14.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[189px] xl:ml-[237px] 2xl:ml-[267px] 3xl:ml-[320px] lg:mr-[4px] xl:mr-[5px] 2xl:mr-[6px] 3xl:mr-[7px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[28%]"
                          size="sm"
                          variant="FillDeeppurpleA700"
                        >
                          Entertaiment{" "}
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_16.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[200px] xl:ml-[250px] 2xl:ml-[282px] 3xl:ml-[338px] lg:mr-[14px] xl:mr-[18px] 2xl:mr-[21px] 3xl:mr-[25px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillRed900"
                        >
                          Political
                        </Button>
                        <Text className="columnentertaiment_" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:ml-[12px] 2xl:mt-[26px] 3xl:ml-[14px] 3xl:mt-[31px] flex items-center justify-center lg:ml-[8px] lg:mt-[18px] text-center w-[36%] xl:ml-[10px] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
                <Column className="items-center justify-start w-[100%]">
                  <Column className="justify-start w-[100%]">
                    <Column
                      className="bg-cover bg-repeat items-center justify-start w-[100%]"
                      style={{
                        backgroundImage: "url('images/img_group38_15.png')",
                      }}
                    >
                      <Column className="bg-gradient  justify-end lg:p-[11px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[19px] w-[100%]">
                        <Button
                          className="font-bold lg:ml-[213px] xl:ml-[266px] 2xl:ml-[300px] 3xl:ml-[360px] lg:mr-[5px] xl:mr-[7px] 2xl:mr-[8px] 3xl:mr-[9px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[14px] lg:text-[8px] text-center tracking-ls1 w-[20%]"
                          size="sm"
                          variant="FillOrangeA700"
                        >
                          Finance
                        </Button>
                        <Text className="columnpolitical_one" variant="body5">
                          Ukraine, 24 april 2022
                        </Text>
                      </Column>
                    </Column>
                    <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[98%]">
                      <Text className="language_One1" as="h6" variant="h6">
                        Zelensky accuses Russia of worst crimes since WW2{" "}
                      </Text>
                      <Text className="description_Two" variant="body5">
                        The Ukrainian leader says Russia must face an
                        international trial as he calls for the country to be
                        thrown off the UN Security Council.
                      </Text>
                    </Column>
                    <Button
                      className="2xl:mt-[26px] 3xl:mt-[31px] flex items-center justify-center lg:mt-[18px] text-center w-[36%] xl:mt-[23px]"
                      rightIcon={
                        <Img
                          src="images/img_upload.svg"
                          className="text-center lg:w-[12px] lg:h-[13px] lg:ml-[3px] xl:w-[16px] xl:h-[17px] xl:ml-[4px] 2xl:w-[18px] 2xl:h-[19px] 2xl:ml-[5px] 3xl:w-[21px] 3xl:h-[22px] 3xl:ml-[6px]"
                          alt="upload"
                        />
                      }
                      shape="RoundedBorder8"
                    >
                      <div className="bg-transparent font-normal not-italic xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] tracking-ls1">
                        Read More
                      </div>
                    </Button>
                  </Column>
                </Column>
              </List>
            </Column>
          </Column>
          <Footer className="xl:mt-[106px] 2xl:mt-[120px] 3xl:mt-[144px] lg:mt-[85px] w-[100%]" />
        </Column>
      </Column>
    </>
  );
};

export default RegisterPage;
