import React from "react";

import { Column, Row, Text, Line, Img } from "components";

const Footer = (props) => {
  return (
    <>
      <footer className={props.className}>
        <Column className="bg-black_900 items-center justify-end lg:p-[42px] xl:p-[53px] 2xl:p-[60px] 3xl:p-[72px] w-[100%]">
          <Column className="items-center justify-start mt-[1px] w-[98%]">
            <Row className="items-start justify-between w-[99%]">
              <Column className="justify-start lg:pt-[3px] xl:pt-[4px] 2xl:pt-[5px] 3xl:pt-[6px] w-[23%]">
                <Column className="items-center justify-start w-[24%]">
                  <Text className="columnneuzy_one" as="h4" variant="h4">
                    Neuzy
                  </Text>
                </Column>
                <Text
                  className="font-inter font-normal lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[40px] not-italic text-white_A700 tracking-ls1 w-[100%]"
                  variant="body4"
                >
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                </Text>
              </Column>
              <Row className="items-start justify-between 2xl:pt-[10px] 3xl:pt-[12px] lg:pt-[7px] xl:pt-[8px] w-[57%]">
                <Column className="justify-start mt-[1px] w-[9%]">
                  <Text className="item" variant="body4">
                    World
                  </Text>
                  <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[100%]">
                    <Text className="columnneuzy_one" variant="body5">
                      Politcts
                    </Text>
                    <Text className="Health_One" variant="body5">
                      Health
                    </Text>
                    <Text className="Business_One" variant="body5">
                      Business
                    </Text>
                    <Text className="Health_One" variant="body5">
                      Tech
                    </Text>
                    <Text className="Business_One" variant="body5">
                      Entertaiment
                    </Text>
                  </Column>
                </Column>
                <Column className="justify-start mt-[1px] w-[7%]">
                  <Text className="item" variant="body4">
                    Tech
                  </Text>
                  <Column className="justify-start lg:mt-[17px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[100%]">
                    <Text className="columnneuzy_one" variant="body5">
                      Siance
                    </Text>
                    <Text
                      className="lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] columnneuzy_one"
                      variant="body5"
                    >
                      Magazine
                    </Text>
                    <Text className="Health_One" variant="body5">
                      Start up
                    </Text>
                    <Text className="Health_One" variant="body5">
                      Crypto
                    </Text>
                  </Column>
                </Column>
                <Column className="justify-start w-[5%]">
                  <Text className="item" variant="body4">
                    Life
                  </Text>
                  <Column className="justify-start lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]">
                    <Text className="columnneuzy_one" variant="body5">
                      Food
                    </Text>
                    <Text
                      className="lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] columnneuzy_one"
                      variant="body5"
                    >
                      Style
                    </Text>
                    <Text className="Health_One" variant="body5">
                      Sport
                    </Text>
                    <Text className="Business_One" variant="body5">
                      Movie
                    </Text>
                    <Text
                      className="lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] columnneuzy_one"
                      variant="body5"
                    >
                      Music
                    </Text>
                  </Column>
                </Column>
                <Column className="justify-start mt-[1px] w-[9%]">
                  <Text className="item" variant="body4">
                    Magezine
                  </Text>
                  <Column className="justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[23px] 3xl:mt-[27px] w-[64%]">
                    <Text className="columnneuzy_one" variant="body5">
                      Fasion
                    </Text>
                    <Text className="Health_One" variant="body5">
                      Blogger
                    </Text>
                    <Text className="Health_One" variant="body5">
                      People
                    </Text>
                  </Column>
                </Column>
                <Column className="justify-start mt-[1px] w-[14%]">
                  <Text className="item" variant="body4">
                    Other
                  </Text>
                  <Column className="justify-start lg:mt-[19px] xl:mt-[24px] 2xl:mt-[28px] 3xl:mt-[33px] w-[100%]">
                    <Text className="columnneuzy_one" variant="body5">
                      About
                    </Text>
                    <Text
                      className="lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] columnneuzy_one"
                      variant="body5"
                    >
                      Contact us
                    </Text>
                    <Text className="Business_One" variant="body5">
                      Terms & Conditions
                    </Text>
                  </Column>
                </Column>
              </Row>
            </Row>
            <Column className="items-center justify-start lg:mt-[37px] xl:mt-[47px] 2xl:mt-[53px] 3xl:mt-[63px] w-[100%]">
              <Line className="bg-white_A700 h-[1px] w-[100%]" />
              <Row className="items-start justify-between lg:mt-[20px] xl:mt-[25px] 2xl:mt-[29px] 3xl:mt-[34px] w-[100%]">
                <Text className="mt-[4px] columnneuzy" variant="body4">
                  Copyright © Neuzy | All Rights Reserved
                </Text>
                <Img
                  src="images/img_group20875.svg"
                  className="lg:h-[18px] xl:h-[22px] 2xl:h-[25px] 3xl:h-[29px] w-[9%]"
                  alt="Group20875"
                />
              </Row>
            </Column>
          </Column>
        </Column>
      </footer>
    </>
  );
};

export default Footer;
