import React from "react";

import { Column, Row, Text, Input, Button } from "components";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const UpdatePasswordPage = () => {
  return (
    <>
      <Column className="bg-white_A700 font-inter items-center justify-start mx-[auto] w-[100%]">
        <Header className="w-[100%]" />
        <Column className="items-center justify-start lg:mt-[49px] xl:mt-[62px] 2xl:mt-[70px] 3xl:mt-[84px] w-[100%]">
          <Row className="items-start justify-between w-[90%]">
            <Column className="justify-start 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] w-[49%]">
              <Text
                className="leading-[normal] ml-[3px] not-italic text-black_900 tracking-ls1 w-[99%]"
                as="h1"
                variant="h1"
              >
                Enter your new password to update the old password
              </Text>
              <Text
                className="font-normal lg:leading-[24px] xl:leading-[31px] 2xl:leading-[35px] 3xl:leading-[42px] xl:mt-[115px] 2xl:mt-[130px] 3xl:mt-[156px] lg:mt-[92px] not-italic text-black_900_87 tracking-ls1 w-[86%]"
                as="h6"
                variant="h6"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown
              </Text>
            </Column>
            <Column className="bg-white_A700 border border-bluegray_900 border-solid items-center justify-end lg:p-[38px] xl:p-[48px] 2xl:p-[54px] 3xl:p-[64px] w-[41%]">
              <Column className="items-center justify-start mt-[4px] rounded-radius10 w-[95%]">
                <Text className="title1" as="h2" variant="h2">
                  Neuzy
                </Text>
                <Column className="justify-start lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] w-[100%]">
                  <Column className="font-poppins items-center justify-start pt-[3px] rounded-radius10 w-[100%]">
                    <Column className="justify-start rounded-radius10 w-[100%]">
                      <Text className="title1" variant="body4">
                        Password
                      </Text>
                      <Input
                        className="placeholder:text-bluegray_400 Input"
                        wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                        type="password"
                        name="Input"
                        placeholder="Enter your Password"
                        shape="RoundedBorder10"
                      ></Input>
                    </Column>
                    <Column className="justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[23px] 3xl:mt-[27px] rounded-radius10 w-[100%]">
                      <Text className="title1" variant="body4">
                        Confirm Password
                      </Text>
                      <Input
                        className="placeholder:text-bluegray_400 Input"
                        wrapClassName="2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] w-[100%] xl:mt-[12px]"
                        type="password"
                        name="Input One"
                        placeholder="Enter your Password"
                        shape="RoundedBorder10"
                      ></Input>
                    </Column>
                  </Column>
                  <Button
                    className="font-medium font-poppins lg:mt-[21px] xl:mt-[26px] 2xl:mt-[30px] 3xl:mt-[36px] lg:text-[11px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[19px] text-center tracking-ls1 w-[100%]"
                    shape="RoundedBorder8"
                    size="2xl"
                    variant="FillBluegray900"
                  >
                    Submit
                  </Button>
                  <Text className="columnregister_one" variant="body4">
                    <span className="text-black_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                      Do you already have an account??{" "}
                    </span>
                    <span className="text-bluegray_900 lg:text-[9px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px]">
                      Login Now
                    </span>
                  </Text>
                </Column>
              </Column>
            </Column>
          </Row>
          <Footer className="xl:mt-[115px] 2xl:mt-[130px] 3xl:mt-[156px] lg:mt-[92px] w-[100%]" />
        </Column>
      </Column>
    </>
  );
};

export default UpdatePasswordPage;
