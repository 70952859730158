import React from "react";

import { Row, Column, Text, Img, Line } from "components";

const Header = (props) => {
  return (
    <>
      <header className={props.className}>
        <Row className="bg-bluegray_900 items-center lg:p-[17px] xl:p-[22px] 2xl:p-[25px] 3xl:p-[30px] w-[100%]">
          <Row className="items-start lg:ml-[34px] xl:ml-[42px] 2xl:ml-[48px] 3xl:ml-[57px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[8px] 3xl:mt-[9px] w-[93%]">
            <Column className="items-center justify-start w-[8%]">
              <Text className="columnneuzy" as="h2" variant="h2">
                Neuzy
              </Text>
            </Column>
            <Row className="items-start justify-center lg:ml-[123px] xl:ml-[153px] 2xl:ml-[173px] 3xl:ml-[207px] mt-[4px] w-[47%]">
              <Text
                className="cursor-pointer hover:font-normal mt-[2px] columnneuzy"
                as="h6"
                variant="h6"
              >
                Sport
              </Text>
              <Text
                className="cursor-pointer hover:font-normal lg:ml-[17px] xl:ml-[21px] 2xl:ml-[24px] 3xl:ml-[28px] my-[1px] columnneuzy"
                as="h6"
                variant="h6"
              >
                Health
              </Text>
              <Text
                className="hover:font-normal Political"
                as="h6"
                variant="h6"
              >
                Political
              </Text>
              <Text
                className="hover:font-normal Political"
                as="h6"
                variant="h6"
              >
                Business
              </Text>
              <Text
                className="hover:font-normal Political"
                as="h6"
                variant="h6"
              >
                Finance
              </Text>
              <Text
                className="hover:font-normal Political"
                as="h6"
                variant="h6"
              >
                Life
              </Text>
              <Text
                className="hover:font-normal Political"
                as="h6"
                variant="h6"
              >
                Entertainment
              </Text>
            </Row>
            <Row className="items-start justify-between lg:ml-[152px] xl:ml-[190px] 2xl:ml-[214px] 3xl:ml-[256px] mt-[1px] w-[15%]">
              <Img
                src="images/img_search.svg"
                className="lg:h-[18px] xl:h-[22px] 2xl:h-[25px] 3xl:h-[29px] mt-[1px] lg:w-[17px] xl:w-[21px] 2xl:w-[24px] 3xl:w-[28px]"
                alt="search"
              />
              <Line className="bg-white_A700 lg:h-[19px] xl:h-[24px] 2xl:h-[27px] 3xl:h-[32px] w-[1px]" />
              <Row className="items-center justify-between lg:mt-[4px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[8px] w-[55%]">
                <Text className="columnneuzy" variant="body4">
                  Login
                </Text>
                <Text className="columnneuzy" variant="body4">
                  Register
                </Text>
              </Row>
            </Row>
          </Row>
        </Row>
      </header>
    </>
  );
};

export default Header;
